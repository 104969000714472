import Vue from 'vue'
import 'bootstrap'
import deepcopy from 'deepcopy';

Vue.filter('formatNumber', function (value) {
    return Math.round(value);
});

new Vue({
    el: '#app',
    //render: h => h(App),
    data: {
        starterHydration: 100,
        flourQuantity: 500,

        receipt: {
            flour: 100,
            water: 70,
            salt: 2,
            sourdough: 20
        },

        additionalProducts: [
            {name: 'Butter', quantity: 0, percnt: 0, isLiquid: false},
            {name: 'Sugar', quantity: 0, percnt: 0, isLiquid: false},
            {name: 'Milk', quantity: 0, percnt: 0, isLiquid: true},
            {name: 'Egg', quantity: 0, percnt: 0, isLiquid: true},
            {name: 'Oil', quantity: 0, percnt: 0, isLiquid: true},
            {name: 'Seeds', quantity: 0, percnt: 0, isLiquid: false},
        ],

        newProduct: {name: '', quantity: 0, percnt: 0},

        receiptProducts: [],
    },

    methods: {
        formatNumber: function (val) {
            return Math.round(val);
        },

        addProduct: function() {
            this.receiptProducts.push(deepcopy(this.newProduct));
            this.$set(this, 'newProduct', {name: '', quantity: 0, percnt: 0});
        },

        changeReceiptProductPercent: function(receiptProduct) {
            receiptProduct.quantity = this.formatNumber(this.flourQuantity * receiptProduct.percent / 100);
        }
    },
    computed: {
        receiptWeight: function () {
            return {
                flour: this.flourQuantity * this.receipt.flour / 100,
                water: this.flourQuantity * this.receipt.water / 100,
                salt: this.flourQuantity * this.receipt.salt / 100,
                sourdough: this.flourQuantity * this.receipt.sourdough / 100,
            };
        },

        totalDoughWeight: function () {
            let weight = this.receiptWeight.flour + this.receiptWeight.water + this.receiptWeight.salt + this.receiptWeight.sourdough;
            this.receiptProducts.forEach(p => weight+= p.quantity);
            //
            return weight;
        },

        totalDoughHydration: function () {
            const sourdoughFlour = (this.receipt.sourdough/100) * (1 - (this.starterHydration/200));
            const sourdoughWater =  (this.receipt.sourdough/100) * (this.starterHydration/200);
            // console.log('Sourdough water: ' + sourdoughWater + ', water: ' + (this.receipt.water/100) );
            // console.log('Sourdough flour: ' + sourdoughFlour + ', flour: ' + (this.receipt.flour/100) );
            //
            // console.log((parseFloat(this.receipt.water)/100) + sourdoughWater);
            // console.log((this.receipt.flour/100) + sourdoughFlour);
            //console.log(this.starterHydration/100 + ' - sour: ' + this.receiptWeight.sourdough + ' flour: ' + sourdoughFlour + ' water ' + sourdoughWater);
            //return 100*((parseFloat(this.receipt.water)/100) + sourdoughWater)/(parseFloat(this.receipt.flour)/100 + sourdoughFlour);

            const water = parseFloat(this.receipt.water)/100;
            const sourdough = parseFloat(this.receipt.sourdough)/100;
            const hydSourdough = parseFloat(this.starterHydration)/100;
console.log(water, sourdough, hydSourdough);
            return 100 * (2*water + hydSourdough * sourdough)/(2 + sourdough);
        }
    },

    watch: {
      newProduct: {
          handler(val){
              this.$set(this.newProduct, 'quantity', parseInt(this.flourQuantity * val.percent/100));
          },
          deep: true
      },
    },

    mounted() {
        // this.receiptWeight =  {
        //     flour: this.flourQuantity * this.receipt.flour / 100,
        //         water: this.flourQuantity * this.receipt.water / 100,
        //         salt: this.flourQuantity * this.receipt.salt / 100,
        //         sourdough: this.flourQuantity * this.receipt.sourdough / 100,
        //         additionalProducts: []
        // };
    }

})